class DataFilter {
  constructor(_filter, _isActive) {
    this._filter = _filter;
    this._isActive = _isActive;
  }
  isActive(filter) {
    return this._isActive(filter);
  }
  filter(filter, list) {
    if (!this.isActive(filter))
      return list;
    return this._filter(list, filter);
  }
}
class IndividualDataFilter extends DataFilter {
  constructor(filter, isActive) {
    super((list, f) => list.filter((x) => filter(x, f)), isActive);
  }
}

export { DataFilter, IndividualDataFilter };
