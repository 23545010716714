import { ReplaySubject, BehaviorSubject, of, merge, startWith, combineLatest, asyncScheduler } from 'rxjs';
import { switchMap, map, distinctUntilChanged, tap, throttleTime, catchError } from 'rxjs/operators';
import Fuse from 'fuse.js';
import { sortByIndexAsc } from '../lib/index-sort.js';
import { mapToArr, arrToMap, mapArrNotNull, applyQueryParam } from '@juulsgaard/ts-tools';
import { cache, mapListChanged, persistentCache, latestValueFromOrDefault } from '@juulsgaard/rxjs-tools';

const _ListDataSource = class _ListDataSource {
  //</editor-fold>
  constructor(options, tableColumns, searchColumns, sortColumns, listConfig, gridConfig) {
    this.options = options;
    this.tableColumns = tableColumns;
    this.searchColumns = searchColumns;
    this.sortColumns = sortColumns;
    this.listConfig = listConfig;
    this.gridConfig = gridConfig;
    this.searchKeys = [];
    //<editor-fold desc="Item Population">
    this._items$ = new ReplaySubject(1);
    this._itemSources$ = new ReplaySubject(1);
    this._recalculate$ = new BehaviorSubject(void 0);
    this.blackList$ = new BehaviorSubject([]);
    //</editor-fold>
    //<editor-fold desc="Search">
    this.searchQuery$ = new BehaviorSubject(void 0);
    this.searchResultLimit = 200;
    this.sorting$ = new BehaviorSubject(_ListDataSource.defaultSorting);
    this._length$ = new BehaviorSubject(0);
    this.length$ = this._length$.asObservable();
    this.columns = mapToArr(tableColumns);
    this.paginated = options.paginated;
    this.indexSorted = options.indexSorted;
    this.listFallbackImage = listConfig?.avatarPlaceholder;
    this.gridFallbackImage = gridConfig?.imagePlaceholder;
    this.sortOptions = [];
    this.columnIds = [];
    this.sortLookup = /* @__PURE__ */ new Map();
    for (let [id, col] of sortColumns) {
      this.sortOptions.push({ id, name: col.title });
      this.sortLookup.set(id, col.sortFn);
      if (col.defaultSort) {
        this.sorting$.next({ direction: options.defaultSortOrder, active: id });
      }
    }
    for (let [id, col] of searchColumns) {
      this.searchKeys.push({ key: id, weight: col.weight });
    }
    for (let [id, col] of tableColumns) {
      this.columnIds.push(col.id);
      if (col.sortFn) {
        this.sortOptions.push({ id: col.id, name: col.title });
        this.sortLookup.set(col.id, col.sortFn);
      }
      if (col.defaultSort) {
        this.sorting$.next({ direction: options.defaultSortOrder, active: col.id });
      }
      if (col.searchable) {
        this.searchKeys.push({ key: id, weight: col.searchWeight });
      }
    }
    if (this.options.actions.length) {
      this.columnIds.push("_actions");
    }
    if (this.options.flags.length) {
      this.columnIds.push("_flags");
    }
    this._page$ = new BehaviorSubject({ page: 0, pageSize: options.pageSize });
    this.page$ = this._page$.asObservable();
    this.filter$ = this.options.filterService?.filter$ ?? of(void 0);
    this.items$ = merge(
      this._items$,
      this._itemSources$.pipe(switchMap((x) => x))
    ).pipe(cache());
    this.itemLookup$ = this.items$.pipe(
      map((list) => arrToMap(list, (x) => x.id, (x) => x)),
      cache()
    );
    this.empty$ = this.items$.pipe(map((x) => !x.length), startWith(true), distinctUntilChanged());
    const filtered$ = combineLatest([this.items$, this.filter$, this.blackList$, this._recalculate$]).pipe(
      map(([x, filter, blacklist]) => this.filter(x, filter, blacklist)),
      map((list) => this.indexSort(list)),
      tap((list) => this.updatePage(list.length))
    );
    const activeFilter$ = this.options.filterService?.activeFilters$?.pipe(
      map((x) => x > 0),
      distinctUntilChanged(),
      cache()
    ) ?? of(false);
    this.filterActive$ = combineLatest([this.blackList$, activeFilter$]).pipe(
      map(([blacklist, filtered]) => !!blacklist.length || filtered),
      cache()
    );
    const searchQuery$ = this.searchQuery$.pipe(
      throttleTime(800, asyncScheduler, { leading: false, trailing: true }),
      startWith(void 0),
      distinctUntilChanged(),
      cache()
    );
    this.searching$ = searchQuery$.pipe(
      map((x) => !!x?.length),
      distinctUntilChanged(),
      cache()
    );
    this.preSearchData$ = filtered$.pipe(
      mapListChanged(this.mapToSearch.bind(this), this.options.pureMapping),
      tap((list) => this.setupSearch(list)),
      cache()
    );
    const searchData$ = combineLatest([
      this.preSearchData$,
      searchQuery$
    ]).pipe(
      map(([, query]) => this.search(query ?? "")),
      map((list) => list.map((x) => x.item.model))
    );
    const listMap = this.listConfig ? mapListChanged(this.mapToList.bind(this), this.options.pureMapping) : map(() => []);
    const gridMap = this.gridConfig ? mapListChanged(this.mapToGrid.bind(this), this.options.pureMapping) : map(() => []);
    this.simpleSearchData$ = searchData$.pipe(
      mapListChanged(this.mapToUniversal.bind(this), this.options.pureMapping),
      persistentCache(500)
    );
    this.tableSearchData$ = this.simpleSearchData$.pipe(
      mapListChanged(this.mapToTable.bind(this), this.options.pureMapping),
      cache()
    );
    this.listSearchData$ = this.simpleSearchData$.pipe(
      listMap,
      cache()
    );
    this.gridSearchData$ = this.simpleSearchData$.pipe(
      gridMap,
      cache()
    );
    const sorted$ = combineLatest([filtered$, this.sorting$]).pipe(
      map(([list, sort]) => this.sort(list, sort)),
      cache()
    );
    const paginated$ = combineLatest([sorted$, this._page$]).pipe(
      map(([list, page]) => this.paginate(list, page))
    );
    this.filteredItems$ = sorted$;
    this.simpleData$ = paginated$.pipe(
      mapListChanged(this.mapToUniversal.bind(this), this.options.pureMapping),
      persistentCache(500)
    );
    this.tableData$ = this.simpleData$.pipe(
      mapListChanged(this.mapToTable.bind(this), this.options.pureMapping),
      cache()
    );
    this.listData$ = this.simpleData$.pipe(
      listMap,
      cache()
    );
    this.gridData$ = this.simpleData$.pipe(
      gridMap,
      cache()
    );
    this.simpleDisplayData$ = this.searching$.pipe(
      switchMap((x) => x ? this.simpleSearchData$ : this.simpleData$)
    );
    this.tableDisplayData$ = this.searching$.pipe(
      switchMap((x) => x ? this.tableSearchData$ : this.tableData$)
    );
    this.listDisplayData$ = this.searching$.pipe(
      switchMap((x) => x ? this.listSearchData$ : this.listData$)
    );
    this.gridDisplayData$ = this.searching$.pipe(
      switchMap((x) => x ? this.gridSearchData$ : this.gridData$)
    );
  }
  get items() {
    return latestValueFromOrDefault(this.items$, []);
  }
  get empty() {
    return this.items.length <= 0;
  }
  /**
   * Populate the data source
   * @param items
   */
  setItems(items) {
    this._items$.next(items);
  }
  /**
   * Populate the data source via observable
   * @param items$
   */
  setItems$(items$) {
    this._itemSources$.next(items$.pipe(catchError(() => of([]))));
  }
  /**
   * Trigger a re-calculation of the data source pipeline
   */
  recalculate() {
    this._recalculate$.next();
  }
  /**
   * Define a list of Ids that will be removed from the final result
   * @param ids
   */
  set blackList(ids) {
    this.blackList$.next(ids ?? []);
  }
  /**
   * Apply the blacklist / service filter in the pipeline
   * @param list - The data
   * @param filter - A filter from the Filter Service
   * @param blacklist - A blacklist to exclude
   * @private
   */
  filter(list, filter, blacklist) {
    if (!list?.length)
      return [];
    if (blacklist?.length) {
      const set = new Set(blacklist);
      list = list.filter((x) => !set.has(x.id));
    }
    if (!filter)
      return list;
    return filter.filter(list);
  }
  //</editor-fold>
  //<editor-fold desc="Map To Universal">
  mapToUniversal(row) {
    const actions = mapArrNotNull(this.options.actions, (action) => this.mapAction(row, action));
    const flags = mapArrNotNull(this.options.flags, (f) => {
      const active = f.filter(row);
      const icon = active ? f.icon : f.inactiveIcon;
      const name = active ? f.name : f.inactiveName ?? f.name;
      return icon ? { icon, name } : null;
    });
    const cssClasses = this.options.cssClasses.filter((style) => style.condition(row)).map((x) => x.cssClass);
    return { model: row, actions, flags, cssClasses };
  }
  //</editor-fold>
  //<editor-fold desc="Map to Table">
  /**
   * Map the raw data to a table format with data as defined by the config
   * @param row - The raw data
   * @private
   */
  mapToTable(row) {
    const data = {};
    this.tableColumns.forEach((col) => {
      data[col.id] = col.mapData(row.model);
    });
    return {
      ...row,
      id: row.model.id,
      data
    };
  }
  /**
   * Add a search map to model
   * @param row - data model
   */
  mapToSearch(row) {
    const search = {};
    for (let [id, col] of this.tableColumns) {
      if (!col.searchable)
        continue;
      const val = col.mapData(row)?.toString();
      if (val !== void 0)
        search[id] = val;
    }
    for (let [id, col] of this.searchColumns) {
      const val = col.mapData(row);
      if (val !== void 0)
        search[id] = val;
    }
    return { model: row, search };
  }
  /**
   * Prepare the search algorithms
   * @param list
   * @private
   */
  setupSearch(list) {
    if (!this.searcher) {
      this.searcher = new Fuse(list, {
        includeScore: true,
        shouldSort: true,
        keys: this.searchKeys.map(({ key, weight }) => ({
          name: ["search", key],
          weight: weight ?? 1
        }))
      });
      return;
    }
    this.searcher.setCollection(list);
  }
  /**
   * Apply the search algorithms
   * @param query
   * @param limit
   * @private
   */
  search(query, limit) {
    return this.searcher.search(query, { limit: limit ?? this.searchResultLimit });
  }
  //</editor-fold>
  //<editor-fold desc="Detached Search">
  /**
   * Generate a detached search feed with a dedicated query
   * @param query$ - The dedicated query
   * @param limit - Limit the amount of search results
   */
  getDetachedSearch(query$, limit = 20) {
    if (!this.listConfig) {
      if (!this.gridConfig) {
        throw Error("Page Search requires either a List or Grid Config");
      }
    }
    const getName = this.listConfig ? (item) => this.listConfig.firstLine(item) : (item) => this.gridConfig.title(item);
    const getIcon = this.listConfig ? (item) => this.listConfig.icon?.(item) : (item) => this.gridConfig.icon?.(item);
    const getExtra = this.listConfig ? (item) => this.listConfig.secondLine?.(item) : (item) => this.gridConfig.subTitle?.(item);
    return combineLatest([this.preSearchData$, query$]).pipe(
      map(([, query]) => this.search(query ?? "", limit)),
      map((list) => list.map((x) => ({
        id: x.item.model.id,
        model: x.item.model,
        name: getName(x.item.model),
        icon: getIcon(x.item.model),
        extra: getExtra(x.item.model),
        score: x.score
      }))),
      cache()
    );
  }
  get sorting() {
    return this.sorting$.value;
  }
  /**
   * Sort the data according to the index
   * (Only applies to ISorted lists)
   * @param list
   * @private
   */
  indexSort(list) {
    if (!this.options.indexSorted)
      return list;
    return [...list].sort(sortByIndexAsc);
  }
  /**
   * Apply the selected sorting
   * If no sorting is supplied then the list is returned as is
   * @param list
   * @param sort
   * @private
   */
  sort(list, sort) {
    if (!sort.active?.length)
      return list;
    if (!sort.direction.length)
      return list;
    const sortFn = this.sortLookup.get(sort.active);
    if (!sortFn)
      return list;
    return [...list].sort(sort.direction == "asc" ? sortFn : (a, b) => -1 * sortFn(a, b));
  }
  /**
   * Change the active sorting, or remove sorting
   * @param sort
   */
  setSort(sort) {
    this.sorting$.next(sort ?? _ListDataSource.defaultSorting);
  }
  //</editor-fold>
  //<editor-fold desc="Map to List">
  /**
   * Map the table data to the more compact List data
   * @param item - Row data
   * @private
   */
  mapToList(item) {
    const icon = this.listConfig.icon?.(item.model);
    return {
      ...item,
      id: item.model.id,
      firstLine: this.listConfig.firstLine(item.model),
      secondLine: this.listConfig.secondLine?.(item.model),
      avatar: this.getImageUrl(item.model, !icon ? this.listConfig.avatarPlaceholder : void 0, this.listConfig.avatar, this.listConfig.avatarCacheBuster),
      icon
    };
  }
  //</editor-fold>
  //<editor-fold desc="Map to Grid">
  /**
   * Map the table data to the re-orderable grid data
   * @param item
   */
  mapToGrid(item) {
    const icon = this.gridConfig.icon?.(item.model);
    return {
      ...item,
      id: item.model.id,
      title: this.gridConfig.title(item.model),
      subTitle: this.gridConfig.subTitle?.(item.model),
      image: this.getImageUrl(item.model, !icon ? this.gridConfig.imagePlaceholder : void 0, this.gridConfig.image, this.gridConfig.imageCacheBuster),
      icon,
      index: item.model.index
    };
  }
  //</editor-fold>
  //<editor-fold desc="Helpers">
  /**
   * Generates the Image URL with fallback and cache buster
   * @param data
   * @param fallback
   * @param getUrl
   * @param getCacheBuster
   */
  getImageUrl(data, fallback, getUrl, getCacheBuster) {
    if (!getUrl)
      return void 0;
    const url = getUrl(data);
    if (!url)
      return fallback;
    if (!getCacheBuster)
      return url;
    const cacheBuster = getCacheBuster(data);
    if (!cacheBuster)
      return url;
    const cbStr = cacheBuster instanceof Date ? (cacheBuster.getTime() / 1e3).toFixed(0) : cacheBuster;
    return applyQueryParam(url, "_cb", cbStr);
  }
  /**
   * Maps an action config to undefined if invalid, or to an action if valid
   * @param data - The row data
   * @param config - The action config
   */
  mapAction(data, config) {
    if (!config.action && !config.route)
      return void 0;
    if (config.filter && !config.filter(data))
      return void 0;
    if (config.route === void 0) {
      return config;
    }
    return { name: config.name, icon: config.icon, color: config.color, newTab: !!config.newTab, route: config.route(data) };
  }
  /**
   * The current pagination page
   */
  get page() {
    return this._page$.value;
  }
  get length() {
    return this._length$.value;
  }
  /**
   * Re-calculate pagination based on the length of the content list
   * @param listLength
   * @private
   */
  updatePage(listLength) {
    this._length$.next(listLength);
    const page = this._page$.value;
    if (listLength == 0 || listLength > page.page * page.pageSize)
      return;
    this._page$.next({ pageSize: page.pageSize, page: Math.floor((listLength - 1) / page.pageSize) });
  }
  /**
   * Apply pagination to the data
   * @param list
   * @param pagination
   * @private
   */
  paginate(list, pagination) {
    if (!this.options.paginated)
      return list;
    return list.slice(pagination.page * pagination.pageSize, (pagination.page + 1) * pagination.pageSize);
  }
  /**
   * Change the location of the pagination
   * @param pageSize
   * @param pageIndex
   */
  setPage({ pageSize, pageIndex }) {
    this._page$.next({ page: pageIndex, pageSize });
  }
  //</editor-fold>
};
//</editor-fold>
//<editor-fold desc="Sorting">
_ListDataSource.defaultSorting = { active: "", direction: "asc" };
let ListDataSource = _ListDataSource;

export { ListDataSource };
