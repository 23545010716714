class CreateAction {
  constructor(name, icon, action) {
    this.name = name;
    this.icon = icon;
    this.action = action;
    this.multiFile = false;
  }
  static Create(name, icon, action) {
    return new CreateAction(name, icon, action);
  }
  get canUpload() {
    return !!this.uploadAction;
  }
  withUpload(action, multiFile = false) {
    this.uploadAction = action;
    this.multiFile = multiFile;
    return this;
  }
  create() {
    this.action();
  }
  async upload(files) {
    if (!this.uploadAction)
      return;
    if (files instanceof FileList)
      files = Array.from(files);
    if (!Array.isArray(files))
      files = [files];
    if (!files.length)
      return;
    if (this.multiFile) {
      for (let file of files) {
        await this.uploadAction(file);
      }
      return;
    }
    await this.uploadAction(files[0]);
  }
}

export { CreateAction };
