import { RenderDataTypes, SortingTypes } from '../models/render-types.js';
import { sortBoolAsc, sortDateAsc, sortAlphAsc, sortNumAsc } from '@juulsgaard/ts-tools';

function getRenderDataTypeSorting(type) {
  switch (type) {
    case RenderDataTypes.Number:
    case RenderDataTypes.Decimal:
      return sortNumAsc();
    case RenderDataTypes.String:
    case RenderDataTypes.Icon:
    case RenderDataTypes.Image:
    case RenderDataTypes.Html:
      return sortAlphAsc();
    case RenderDataTypes.Date:
    case RenderDataTypes.DateTime:
    case RenderDataTypes.Time:
      return sortDateAsc();
    case RenderDataTypes.Bool:
      return sortBoolAsc();
    case RenderDataTypes.Template:
    case RenderDataTypes.Void:
      return void 0;
  }
}
function getSortingTypeSorting(type) {
  switch (type) {
    case SortingTypes.Alph:
      return sortAlphAsc();
    case SortingTypes.Num:
      return sortNumAsc();
    case SortingTypes.Date:
      return sortDateAsc();
    case SortingTypes.Bool:
      return sortBoolAsc();
  }
  return void 0;
}

export { getRenderDataTypeSorting, getSortingTypeSorting };
