import { BehaviorSubject, merge, EMPTY, combineLatest } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { cache } from '@juulsgaard/rxjs-tools';
import { isString } from '@juulsgaard/ts-tools';

class ListState {
  constructor(dataSource, id$) {
    this._itemId$ = new BehaviorSubject(void 0);
    const lookup$ = dataSource.itemLookup$;
    this.itemId$ = merge(this._itemId$, id$ ?? EMPTY).pipe(cache(), distinctUntilChanged());
    this.item$ = combineLatest([lookup$, this.itemId$]).pipe(
      map(([lookup, id]) => id ? lookup.get(id) : void 0),
      cache()
    );
  }
  subscribe(observer) {
    return this.item$.subscribe(observer);
  }
  setItem(value) {
    const id = isString(value) ? value : value?.id;
    this._itemId$.next(id);
  }
}

export { ListState };
