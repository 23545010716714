import { ReplaySubject, BehaviorSubject, of, combineLatest, merge, auditTime, startWith, asyncScheduler } from 'rxjs';
import { map, distinctUntilChanged, switchMap, tap, throttleTime, catchError } from 'rxjs/operators';
import Fuse from 'fuse.js';
import { cache, persistentCache, latestValueFromOrDefault } from '@juulsgaard/rxjs-tools';
import { titleCase, applySelector, arrToMap, arrToLookup, mapArrNotNull, mapToArr } from '@juulsgaard/ts-tools';

class TreeDataSource {
  //</editor-fold>
  constructor(options, searchColumns, hiddenSearchColumn, hiddenSortColumns, treeConfig) {
    this.options = options;
    this.searchColumns = searchColumns;
    this.hiddenSearchColumn = hiddenSearchColumn;
    this.hiddenSortColumns = hiddenSortColumns;
    this.treeConfig = treeConfig;
    this.sortOptions = [];
    this.hiddenSortOptions = [];
    this.sortLookup = /* @__PURE__ */ new Map();
    this.searchConfigs = /* @__PURE__ */ new Map();
    //<editor-fold desc="Folder Population">
    this._folders$ = new ReplaySubject(1);
    this._folderSources$ = new ReplaySubject(1);
    this._recalculateFolders$ = new BehaviorSubject(void 0);
    //</editor-fold>
    //<editor-fold desc="Item Population">
    this._items$ = new ReplaySubject(1);
    this._itemSources$ = new ReplaySubject(1);
    this._recalculateItems$ = new BehaviorSubject(void 0);
    this.itemBlackList$ = new BehaviorSubject([]);
    this.folderBlackList$ = new BehaviorSubject([]);
    //</editor-fold>
    //<editor-fold desc="Search">
    this.searchQuery$ = new BehaviorSubject(void 0);
    this.searchResultLimit = 100;
    //</editor-fold>
    //<editor-fold desc="Sorting">
    this.sorting$ = new BehaviorSubject({ active: "", direction: "asc" });
    if (!options.itemParentId && !options.folderChildren) {
      throw Error("Tree Data Source need either itemParentId or folderChildren defined");
    }
    this.hasActions = !!options.folderActions.length || !!options.itemActions.length;
    this.columns = [...this.searchColumns];
    for (let col of hiddenSortColumns) {
      this.sortOptions.push({ id: col.id, name: col.title });
      this.hiddenSortOptions.push({ id: col.id, name: col.title });
      this.sortLookup.set(col.id, { ...col });
    }
    for (let col of hiddenSearchColumn) {
      this.searchConfigs.set(col.id, col);
    }
    for (let col of searchColumns) {
      if (col.sorting) {
        this.sortOptions.push({ id: col.id, name: col.title ?? titleCase(col.id) });
        this.sortLookup.set(col.id, col.sorting);
      }
      if (col.searching) {
        this.searchConfigs.set(col.id, col.searching);
      }
    }
    this.folderFilter$ = this.options.folderFilterService?.filter$ ?? of(void 0);
    const folderFilterActive$ = this.options.folderFilterService?.activeFilters$?.pipe(
      map((x) => x > 0),
      distinctUntilChanged()
    ) ?? of(false);
    this.foldersFiltered$ = combineLatest([this.folderBlackList$, folderFilterActive$]).pipe(
      map(([blacklist, filtered]) => !!blacklist.length || filtered),
      distinctUntilChanged(),
      cache()
    );
    this.itemFilter$ = this.options.itemFilterService?.filter$ ?? of(void 0);
    const itemFilterActive$ = this.options.itemFilterService?.activeFilters$?.pipe(
      map((x) => x > 0),
      distinctUntilChanged()
    ) ?? of(false);
    this.itemsFiltered$ = combineLatest([this.itemBlackList$, itemFilterActive$]).pipe(
      map(([blacklist, filtered]) => !!blacklist.length || filtered),
      distinctUntilChanged(),
      cache()
    );
    this.canMoveFolder$ = options.moveActions.moveFolder ? this.foldersFiltered$.pipe(map((x) => !x)) : of(false);
    this.canMoveItem$ = options.moveActions.moveItem ? this.itemsFiltered$.pipe(map((x) => !x)) : of(false);
    this.onFolderRelocate = options.moveActions.relocateFolders;
    this.onItemRelocate = options.moveActions.relocateItems;
    this.onFolderMove = options.moveActions.moveFolder;
    this.onItemMove = options.moveActions.moveItem;
    this.folders$ = merge(
      this._folders$,
      this._folderSources$.pipe(switchMap((x) => x))
    ).pipe(cache());
    if (options.folderParentId) {
      const folderParentId = options.folderParentId;
      this.baseFolders$ = this.folders$.pipe(
        map((folders) => folders.map((folder) => ({
          model: folder,
          parentId: applySelector(folder, folderParentId)
        }))),
        cache()
      );
    } else {
      this.baseFolders$ = this.folders$.pipe(
        map((folders) => folders.map((folder) => ({ model: folder }))),
        cache()
      );
    }
    if (options.folderChildren) {
      const folderChildren = options.folderChildren;
      this.items$ = this.folders$.pipe(
        map((list) => list.flatMap((folder) => applySelector(folder, folderChildren))),
        cache()
      );
      this.baseItems$ = this.folders$.pipe(
        map((list) => list.flatMap(
          (folder) => applySelector(folder, folderChildren).map((item) => ({
            folderId: folder.id,
            model: item
          }))
        )),
        cache()
      );
    } else if (options.itemParentId) {
      const itemParentId = options.itemParentId;
      this.items$ = merge(
        this._items$,
        this._itemSources$.pipe(switchMap((x) => x))
      ).pipe(cache());
      this.baseItems$ = this.items$.pipe(
        map((list) => list.map((item) => ({
          folderId: applySelector(item, itemParentId),
          model: item
        }))),
        cache()
      );
    } else {
      throw Error("Invalid state");
    }
    const nestedData$ = combineLatest([this.baseFolders$, this.baseItems$]).pipe(
      auditTime(0),
      map(([folders, items]) => this.nestData(folders, items)),
      cache()
    );
    this.metaFolders$ = nestedData$.pipe(
      map(({ folders }) => folders),
      cache()
    );
    this.metaItems$ = nestedData$.pipe(
      map(({ items }) => items),
      cache()
    );
    this.folderLookup$ = this.folders$.pipe(
      map((list) => arrToMap(list, (x) => x.id, (x) => x)),
      cache()
    );
    this.baseFolderLookup$ = this.baseFolders$.pipe(
      map((list) => arrToMap(list, (x) => x.model.id, (x) => x)),
      cache()
    );
    this.metaFolderLookup$ = this.metaFolders$.pipe(
      map((list) => arrToMap(list, (x) => x.model.id, (x) => x)),
      cache()
    );
    this.itemLookup$ = this.items$.pipe(
      map((list) => arrToMap(list, (x) => x.id, (x) => x)),
      cache()
    );
    this.baseItemLookup$ = this.baseItems$.pipe(
      map((list) => arrToMap(list, (x) => x.model.id, (x) => x)),
      cache()
    );
    this.metaItemLookup$ = this.metaItems$.pipe(
      map((list) => arrToMap(list, (x) => x.model.id, (x) => x)),
      cache()
    );
    this.foldersEmpty$ = this.folders$.pipe(map((x) => !x.length), startWith(true), distinctUntilChanged());
    this.itemsEmpty$ = this.items$.pipe(map((x) => !x.length), startWith(true), distinctUntilChanged());
    const filteredFolders$ = combineLatest([this.baseFolders$, this.folderFilter$, this.folderBlackList$]).pipe(
      map(([x, filter, blacklist]) => this.filterFolders(x, filter, blacklist)),
      cache()
    );
    const filteredItems$ = combineLatest([this.baseItems$, this.itemFilter$, this.itemBlackList$]).pipe(
      map(([x, filter, blacklist]) => this.filterItems(x, filter, blacklist)),
      cache()
    );
    const filteredNestedData$ = combineLatest([filteredFolders$, filteredItems$]).pipe(
      auditTime(0),
      map(([folders, items]) => this.nestData(folders, items)),
      cache()
    );
    this.filteredFolderLookup$ = filteredNestedData$.pipe(
      map((x) => x.folders),
      map((folders) => arrToMap(folders, (x) => x.model.id, (x) => x)),
      persistentCache(500)
    );
    this.treeData$ = filteredNestedData$.pipe(
      map(({ folders }) => this.mapToTree(folders)),
      cache()
    );
    this.folderSearchData$ = filteredNestedData$.pipe(
      map((x) => x.folders),
      map((x) => this.mapFolderSearchData(x)),
      tap((list) => this.setupFolderSearch(list)),
      cache()
    );
    this.itemSearchData$ = filteredNestedData$.pipe(
      map((x) => x.items),
      map((x) => this.mapItemSearchData(x)),
      tap((list) => this.setupItemSearch(list)),
      cache()
    );
    const searchQuery$ = this.searchQuery$.pipe(
      throttleTime(800, asyncScheduler, { leading: false, trailing: true }),
      startWith(void 0),
      distinctUntilChanged(),
      cache()
    );
    this.searching$ = searchQuery$.pipe(
      map((x) => !!x?.length),
      distinctUntilChanged(),
      cache()
    );
    const folderSearchData = combineLatest([this.folderSearchData$, searchQuery$]).pipe(
      map(([, q]) => this.searchFolders(q ?? "")),
      cache()
    );
    const itemSearchData = combineLatest([this.itemSearchData$, searchQuery$]).pipe(
      map(([, q]) => this.searchItems(q ?? "")),
      cache()
    );
    const mergedSearchData = combineLatest([folderSearchData, itemSearchData]).pipe(
      auditTime(0),
      map(([folders, items]) => [...folders, ...items]),
      map((list) => list.sort((a, b) => (a?.score ?? 0) - (b?.score ?? 0))),
      map((list) => list.map((x) => x.item))
    );
    this.searchResult$ = combineLatest([mergedSearchData, this.sorting$]).pipe(
      map(([list, sort]) => this.sort(list, sort)),
      map((list) => this.mapSearchRows(list)),
      cache()
    );
    const cleanFolderSearchData = folderSearchData.pipe(map((list) => list.map((x) => x.item)));
    this.folderSearchResult$ = combineLatest([cleanFolderSearchData, this.sorting$]).pipe(
      map(([list, sort]) => this.sort(list, sort)),
      map((list) => this.mapSearchRows(list)),
      cache()
    );
    const cleanItemSearchData = itemSearchData.pipe(map((list) => list.map((x) => x.item)));
    this.itemSearchResult$ = combineLatest([cleanItemSearchData, this.sorting$]).pipe(
      map(([list, sort]) => this.sort(list, sort)),
      map((list) => this.mapSearchRows(list)),
      cache()
    );
  }
  get folders() {
    return latestValueFromOrDefault(this.folders$, []);
  }
  get foldersEmpty() {
    return this.folders.length <= 0;
  }
  /**
   * Populate folder list
   * This triggers all affected data sources to re-evaluate
   * @param folders - A list of Folders
   */
  setFolders(folders) {
    this._folders$.next(folders);
  }
  /**
   * Populate the folder data via observable
   * @param folders$
   */
  setFolders$(folders$) {
    this._folderSources$.next(folders$.pipe(catchError(() => of([]))));
  }
  /**
   * Trigger a re-calculation of the folder data source pipeline
   */
  recalculateFolders() {
    this._recalculateFolders$.next();
  }
  get items() {
    return latestValueFromOrDefault(this.items$, []);
  }
  get itemsEmpty() {
    return this.items.length <= 0;
  }
  /**
   * Populate item list
   * This triggers all affected data sources to re-evaluate
   * @param items - A list of Items
   */
  setItems(items) {
    this._items$.next(items);
  }
  /**
   * Populate the item data via observable
   * @param items$
   */
  setItems$(items$) {
    this._itemSources$.next(items$.pipe(catchError(() => of([]))));
  }
  /**
   * Trigger a re-calculation of the item data source pipeline
   */
  recalculateItems() {
    this._recalculateItems$.next();
  }
  /**
   * Define a list of Item Ids that will be removed from the final result
   * @param ids
   */
  set itemBlackList(ids) {
    this.itemBlackList$.next(ids ?? []);
  }
  /**
   * Define a list of Folder Ids that will be removed from the final result
   * @param ids
   */
  set folderBlackList(ids) {
    this.folderBlackList$.next(ids ?? []);
  }
  /**
   * Filters a list of DeepFolders
   * @param list - Folders
   * @param filter - The filter state
   * @param blacklist - Folders to exclude
   * @return folders - A filtered list of folders
   * @private
   */
  filterFolders(list, filter, blacklist) {
    if (blacklist?.length) {
      const set = new Set(blacklist);
      list = list.filter((x) => !set.has(x.model.id));
    }
    if (!filter)
      return list;
    return filter.filter(list);
  }
  /**
   * Filters a list of Items
   * @param list - List of Items
   * @param filter - The filter state
   * @param blacklist - Items to exclude
   * @return items - Filtered list of Items
   * @private
   */
  filterItems(list, filter, blacklist) {
    if (!list)
      return [];
    if (blacklist?.length) {
      const set = new Set(blacklist);
      list = list.filter((x) => !set.has(x.model.id));
    }
    if (!filter)
      return list;
    return filter.filter(list);
  }
  //</editor-fold>
  //<editor-fold desc="Nest Data">
  /**
   * Turns an Item and Folders list into a nested data structure with added metadata to folders
   * @param folders - A list of Folders
   * @param items - A list of Items
   * @return nested data - A list of all folders with added Metadata
   * @private
   */
  nestData(folders, items) {
    if (!folders?.length)
      return { folders: [], items: [] };
    const mappedItems = items.map((x) => ({ ...x }));
    const folderItemLookup = arrToLookup(mappedItems, (x) => x.folderId, (x) => x);
    const mappedFolders = folders.map((folder) => {
      const items2 = folderItemLookup.get(folder.model.id) ?? [];
      if (this.options.itemSort) {
        items2.sort((a, b) => this.options.itemSort(a.model, b.model));
      }
      const newFolder = {
        ...folder,
        items: items2,
        folders: [],
        folderCount: 0,
        itemCount: items2.length,
        path: []
      };
      for (let item of items2) {
        item.folder = newFolder;
      }
      return newFolder;
    });
    if (!this.options.folderParentId) {
      return { folders: mappedFolders, items: mappedItems };
    }
    const folderLookup = arrToLookup(mappedFolders, (x) => x.parentId, (x) => x);
    for (let folder of mappedFolders) {
      const subFolders = folderLookup.get(folder.model.id) ?? [];
      if (this.options.folderSort) {
        subFolders.sort((a, b) => this.options.folderSort(a.model, b.model));
      }
      folder.folders = subFolders;
    }
    const root = folderLookup.get(void 0) ?? [];
    for (let folder of root) {
      this.populateDeepFolder(folder, []);
    }
    return { folders: mappedFolders, items: mappedItems };
  }
  /**
   * Populates deep folders with itemCount and paths
   * @param folder - Current Folder
   * @param path - Current path
   * @return counts - The item and folder count of the folder
   * @private
   */
  populateDeepFolder(folder, path) {
    folder.path = path;
    let folderCount = folder.folders.length;
    let itemCount = folder.items.length;
    const newPath = [...path, folder];
    for (let subFolder of folder.folders) {
      const counts = this.populateDeepFolder(subFolder, newPath);
      folderCount += counts.folderCount;
      itemCount += counts.itemCount;
    }
    folder.folderCount = folderCount;
    folder.itemCount = itemCount;
    return { folderCount, itemCount };
  }
  //</editor-fold>
  //<editor-fold desc="Action Mapping">
  mapFolderActions(folder) {
    return mapArrNotNull(
      this.options.folderActions,
      (config) => {
        if (!config.action && !config.route)
          return void 0;
        if (config.filter && !config.filter(folder.model, folder))
          return void 0;
        if (config.route === void 0) {
          return config;
        }
        return { name: config.name, icon: config.icon, color: config.color, newTab: !!config.newTab, route: config.route(folder.model, folder) };
      }
    );
  }
  mapItemActions(item) {
    return mapArrNotNull(
      this.options.itemActions,
      (config) => {
        if (!config.action && !config.route)
          return void 0;
        if (config.filter && !config.filter(item.model, item))
          return void 0;
        if (config.route === void 0) {
          return config;
        }
        return { name: config.name, icon: config.icon, color: config.color, newTab: !!config.newTab, route: config.route(item.model, item) };
      }
    );
  }
  //</editor-fold>
  //<editor-fold desc="Flag Mapping">
  mapFolderFlags(folder) {
    return mapArrNotNull(
      this.options.folderFlags,
      (config) => {
        const active = !config.filter || config.filter(folder.model, folder);
        if (active)
          return { name: config.name, icon: config.icon };
        if (!config.inactiveIcon)
          return void 0;
        return { name: config.inactiveName ?? config.name, icon: config.inactiveIcon };
      }
    );
  }
  mapItemFlags(item) {
    return mapArrNotNull(
      this.options.itemFlags,
      (config) => {
        const active = !config.filter || config.filter(item.model, item);
        if (active)
          return { name: config.name, icon: config.icon };
        if (!config.inactiveIcon)
          return void 0;
        return { name: config.inactiveName ?? config.name, icon: config.inactiveIcon };
      }
    );
  }
  //</editor-fold>
  //<editor-fold desc="Sidebar Data">
  getSidebarData(folderId$) {
    return folderId$.pipe(
      switchMap((folderId) => this.filteredFolderLookup$.pipe(
        map((lookup) => this.mapSidebarData(folderId, lookup))
      )),
      cache()
    );
  }
  mapSidebarData(folderId, lookup) {
    const folder = folderId ? lookup.get(folderId) : void 0;
    if (!folder)
      return this.mapSidebarRoot(lookup);
    const folders = folder.folders.map((x) => this.mapSidebarFolder(x));
    const items = folder.items.map((x) => this.mapSidebarItem(x));
    const path = folder.path.map((x) => ({ name: this.treeConfig?.folderName(x.model, x) ?? "--", model: x }));
    return {
      model: folder,
      name: this.treeConfig?.folderName?.(folder.model, folder) ?? "N/A",
      icon: this.getFolderIcon(folder),
      bonus: this.treeConfig?.folderBonus?.(folder.model, folder),
      folders,
      items,
      actions: this.mapFolderActions(folder),
      flags: this.mapFolderFlags(folder),
      path
    };
  }
  mapSidebarRoot(lookup) {
    const folders = mapToArr(lookup).filter((folder) => folder.parentId == void 0).map((folder) => this.mapSidebarFolder(folder));
    return {
      model: void 0,
      name: "Root",
      icon: folders.length > 0 ? "fad fa-folder" : "fad fa-folder-blank",
      folders,
      items: [],
      actions: [],
      flags: [],
      path: []
    };
  }
  mapSidebarFolder(folder) {
    return {
      model: folder,
      name: this.treeConfig?.folderName(folder.model, folder) ?? "N/A",
      bonus: this.treeConfig?.folderBonus?.(folder.model, folder),
      icon: this.getFolderIcon(folder),
      actions: this.mapFolderActions(folder),
      flags: this.mapFolderFlags(folder)
    };
  }
  mapSidebarItem(item) {
    return {
      model: item,
      name: this.treeConfig?.itemName(item.model, item) ?? "N/A",
      bonus: this.treeConfig?.itemBonus?.(item.model, item),
      icon: this.getItemIcon(item),
      actions: this.mapItemActions(item),
      flags: this.mapItemFlags(item)
    };
  }
  //</editor-fold>
  //<editor-fold desc="Map to Tree">
  /**
   * Maps a list of Deep Folders into the display type for trees
   * @param folders - A list of Deep Folders
   * @return treeRoot - A nested structure with all tree display data
   * @private
   */
  mapToTree(folders) {
    const root = folders.filter((x) => x.parentId == void 0);
    if (this.options.folderSort) {
      root.sort((a, b) => this.options.folderSort(a.model, b.model));
    }
    if (!this.treeConfig)
      return [];
    return root.map((x) => this.mapTreeFolder(x));
  }
  /**
   * Recursively maps Deep Folders to a display format
   * @param folder - The current Deep Folder
   * @return treeFolder - A deep folder in display format
   * @private
   */
  mapTreeFolder(folder) {
    const folders = folder.folders.map((x) => this.mapTreeFolder(x));
    const items = folder.items.map((x) => this.mapTreeItem(x));
    return {
      model: folder,
      items,
      folders,
      actions: this.mapFolderActions(folder),
      flags: this.mapFolderFlags(folder),
      data: {
        name: this.treeConfig.folderName(folder.model, folder),
        icon: this.getFolderIcon(folder),
        bonus: this.treeConfig.folderBonus?.(folder.model, folder),
        tooltip: this.treeConfig.folderTooltip?.(folder.model, folder)
      }
    };
  }
  /**
   * Maps a list of items to a display format
   * @param item - The item to map
   * @return treeItem - The mapped item in display format
   * @private
   */
  mapTreeItem(item) {
    return {
      model: item,
      actions: this.mapItemActions(item),
      flags: this.mapItemFlags(item),
      data: {
        icon: this.getItemIcon(item),
        name: this.treeConfig.itemName(item.model, item),
        bonus: this.treeConfig.itemBonus?.(item.model, item),
        tooltip: this.treeConfig.itemTooltip?.(item.model, item)
      }
    };
  }
  //</editor-fold>
  //<editor-fold desc="Map to Search">
  /**
   * Maps Folders to searchable variants
   * @param folders - The Folders
   * @return searchFolders - searchable variants of the Folder
   * @private
   */
  mapFolderSearchData(folders) {
    return folders.map((folder) => {
      const search = {};
      for (let [id, conf] of this.searchConfigs) {
        if (!conf.mapFolder)
          continue;
        const val = conf.mapFolder(folder.model, folder);
        if (val !== void 0)
          search[id] = val;
      }
      return { model: folder, isFolder: true, search };
    });
  }
  /**
   * Maps an item to searchable variants
   * @param items - The Items
   * @return searchItems - The mapped search variants of the Item
   * @private
   */
  mapItemSearchData(items) {
    return items.map((item) => {
      const search = {};
      for (let [id, conf] of this.searchConfigs) {
        if (!conf.mapItem)
          continue;
        const val = conf.mapItem(item.model, item);
        if (val !== void 0)
          search[id] = val;
      }
      return { model: item, isFolder: false, search };
    });
  }
  mapSearchRows(list) {
    return list.map(({ search, ...row }) => {
      const data = {};
      for (let col of this.searchColumns) {
        data[col.id] = row.isFolder ? col.folder.mapData(row.model.model, row.model) : col.item.mapData(row.model.model, row.model);
      }
      if (row.isFolder) {
        return {
          ...row,
          data,
          actions: this.mapFolderActions(row.model),
          flags: this.mapFolderFlags(row.model)
        };
      }
      return {
        ...row,
        data,
        actions: this.mapItemActions(row.model),
        flags: this.mapItemFlags(row.model)
      };
    });
  }
  clearSearch() {
    this.searchQuery$.next(void 0);
  }
  /**
   * Register Folders for Fuzzy Search
   * @param folders - Folder search data
   * @private
   */
  setupFolderSearch(folders) {
    if (this.folderSearcher) {
      this.folderSearcher.setCollection(folders);
      return;
    }
    this.folderSearcher = new Fuse(folders, {
      includeScore: true,
      shouldSort: true,
      keys: mapToArr(this.searchConfigs, (col, key) => ({ key, col })).filter(({ col }) => !!col.mapFolder).map(({ key, col }) => ({
        name: ["search", key],
        weight: col.weight ?? 1
      }))
    });
  }
  /**
   * Register Items for Fuzzy Search
   * @param items - Item search data
   * @private
   */
  setupItemSearch(items) {
    if (this.itemSearcher) {
      this.itemSearcher.setCollection(items);
      return;
    }
    this.itemSearcher = new Fuse(items, {
      includeScore: true,
      shouldSort: true,
      keys: mapToArr(this.searchConfigs, (val, key) => ({ key, val })).filter(({ val }) => !!val.mapItem).map(({ key }) => ["search", key])
    });
  }
  /**
   * Search folders
   * @param query - Search Query
   * @param limit
   * @return folders - Folders that match the search query
   * @private
   */
  searchFolders(query, limit) {
    return this.folderSearcher.search(query, { limit: limit ?? this.searchResultLimit });
  }
  /**
   * Search items
   * @param query - Search Query
   * @param limit
   * @return items - Items that match the search query
   * @private
   */
  searchItems(query, limit) {
    return this.itemSearcher.search(query, { limit: limit ?? this.searchResultLimit });
  }
  //</editor-fold>
  //<editor-fold desc="Detached Search">
  /**
   * Generate a detached search feed with a dedicated query for Folders
   * @param query$ - The dedicated query
   * @param limit - Limit the amount of search results
   */
  getDetachedFolderSearch(query$, limit = 20) {
    return combineLatest([this.folderSearchData$, query$]).pipe(
      map(([, query]) => this.searchFolders(query ?? "", 20)),
      map((list) => list.map(({ score, item }) => ({
        id: item.model.model.id,
        model: item.model,
        name: this.treeConfig?.folderName(item.model.model, item.model),
        icon: this.getFolderIcon(item.model),
        extra: this.treeConfig?.folderBonus?.(item.model.model, item.model),
        score
      }))),
      cache()
    );
  }
  /**
   * Generate a detached search feed with a dedicated query for Items
   * @param query$ - The dedicated query
   * @param limit - Limit the amount of search results
   */
  getDetachedItemSearch(query$, limit = 20) {
    return combineLatest([this.itemSearchData$, query$]).pipe(
      map(([, query]) => this.searchItems(query ?? "", 20)),
      map((list) => list.map(({ score, item }) => ({
        id: item.model.model.id,
        model: item.model,
        name: this.treeConfig?.itemName(item.model.model, item.model),
        icon: this.getItemIcon(item.model),
        extra: this.treeConfig?.itemBonus?.(item.model.model, item.model),
        score
      }))),
      cache()
    );
  }
  get sorting() {
    return this.sorting$.value;
  }
  /**
   * Sort search results
   * @param list - Merged tree search results
   * @param sort - Sort options
   * @return sortedList - Sorted search results
   * @private
   */
  sort(list, sort) {
    if (!sort.active?.length)
      return list;
    if (!sort.direction.length)
      return list;
    const sortConfig = this.sortLookup.get(sort.active);
    if (!sortConfig)
      return list;
    const mapped = list.map((row) => ({
      data: row,
      sort: row.isFolder ? sortConfig.folderSortData(row.model.model, row.model) : sortConfig.itemSortData(row.model.model, row.model)
    }));
    const sortFn = (a, b) => sortConfig.sortFn(a.sort, b.sort);
    return mapped.sort(sort.direction == "asc" ? sortFn : (a, b) => -1 * sortFn(a, b)).map((x) => x.data);
  }
  /**
   * Set the current sorting config
   * @param sort
   */
  setSort(sort) {
    this.sorting$.next(sort);
  }
  //</editor-fold>
  //<editor-fold desc="Utility">
  /**
   * Map a folder icon
   * @param folder - The folder
   * @return icon - The mapped icon value
   * @private
   */
  getFolderIcon(folder) {
    return this.treeConfig?.folderIcon?.(folder.model, folder) ?? (folder.itemCount > 0 || folder.folderCount > 0 ? "fas fa-folder" : "fas fa-folder-blank");
  }
  getItemIcon(item) {
    return this.treeConfig?.itemIcon?.(item.model, item) ?? "fas fa-box";
  }
  //</editor-fold>
}

export { TreeDataSource };
