import { isString } from '@juulsgaard/ts-tools';
import { BehaviorSubject, of, shareReplay } from 'rxjs';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { cache, ObservableSet } from '@juulsgaard/rxjs-tools';

class TreeSelection {
  constructor(dataSource) {
    this.dataSource = dataSource;
    this.multiple = false;
    this._itemId$ = new BehaviorSubject(void 0);
    this.itemId$ = this._itemId$.pipe(distinctUntilChanged());
    this.itemIdLookup$ = this.itemId$.pipe(map((x) => new Set(x ? [x] : [])));
    const baseItem$ = this.itemId$.pipe(
      switchMap((id) => !id ? of(void 0) : this.dataSource.baseItemLookup$.pipe(
        map((lookup) => lookup.get(id))
      )),
      cache()
    );
    this.item$ = baseItem$.pipe(
      map((x) => x?.model)
    );
    this.empty$ = baseItem$.pipe(
      map((x) => !x)
    );
  }
  subscribe(observer) {
    return this.item$.subscribe(observer);
  }
  /**
   * Toggle the item in the selection
   * @param item - The item to toggle
   * @param state - A forced state (`true` = always add, `false` = always delete)
   * @returns The applied change (`true` = item added, `false` = item removed, `undefined` = nothing changed)
   */
  toggleItem(item, state) {
    const id = isString(item) ? item : item?.id;
    if (this._itemId$.value === id) {
      if (state === true)
        return void 0;
      this._itemId$.next(void 0);
      return false;
    }
    if (state === false)
      return void 0;
    this._itemId$.next(id);
    return true;
  }
  setItem(item) {
    const id = isString(item) ? item : item?.id;
    this._itemId$.next(id);
  }
  isActive$(folder) {
    const id = isString(folder) ? folder : folder.id;
    return this.itemId$.pipe(
      map((activeId) => activeId ? activeId === id : false)
    );
  }
}
class TreeRange {
  constructor(dataSource) {
    this.dataSource = dataSource;
    this.multiple = true;
    this._itemIds$ = new ObservableSet();
    this.itemIds$ = this._itemIds$.value$;
    this.itemIdArray$ = this._itemIds$.array$;
    const baseItems$ = this.itemIdArray$.pipe(
      switchMap((ids) => !ids.length ? of([]) : this.dataSource.baseItemLookup$.pipe(
        map((lookup) => ids.map((x) => lookup.get(x)).filter((x) => !!x))
      )),
      cache()
    );
    this.items$ = baseItems$.pipe(
      map((list) => list.map((x) => x.model))
    );
    this.empty$ = baseItems$.pipe(
      map((x) => !x.length)
    );
  }
  get itemIds() {
    return this._itemIds$.value;
  }
  get itemIdArray() {
    return this._itemIds$.array;
  }
  subscribe(observer) {
    return this.items$.subscribe(observer);
  }
  /**
   * Toggle an item in the selection
   * @param item - The item to toggle
   * @param state - A forced state (`true` = always add, `false` = always delete)
   * @returns The applied change (`true` = item added, `false` = item removed, `undefined` = nothing changed)
   */
  toggleItem(item, state) {
    const id = isString(item) ? item : item.id;
    return this._itemIds$.toggle(id, state);
  }
  setRange(list) {
    this._itemIds$.set(list.map((x) => isString(x) ? x : x.id));
  }
  clear() {
    this._itemIds$.clear();
  }
  //<editor-fold desc="Toggle Entire Folder">
  toggleFolder(folder, checked) {
    this._itemIds$.modify((set) => this._toggleFolder(folder, checked, set));
  }
  _toggleFolder(folder, checked, set) {
    for (let item of folder.items) {
      if (checked) {
        set.add(item.model.id);
      } else {
        set.delete(item.model.id);
      }
    }
    for (let subFolder of folder.folders) {
      this._toggleFolder(subFolder, checked, set);
    }
  }
  //</editor-fold>
  //<editor-fold desc="Folder Checkbox State">
  getFolderState$(folder) {
    if (folder.itemCount < 1)
      return [of(false), of(false)];
    const state$ = this.itemIds$.pipe(
      map((lookup) => {
        if (!lookup.size)
          return "none";
        return this.getFolderState(lookup.size < folder.itemCount ? "none" : void 0, folder, lookup);
      }),
      shareReplay({ refCount: true, bufferSize: 1 })
    );
    return [state$.pipe(map((x) => x === "all")), state$.pipe(map((x) => x === "some"))];
  }
  getFolderState(state, folder, lookup) {
    const itemState = this.getFolderItemState(folder, lookup);
    if (itemState) {
      if (itemState === "some")
        return "some";
      if (!state)
        state = itemState;
      if (itemState !== state)
        return "some";
    }
    for (let subFolder of folder.folders) {
      const subState = this.getFolderState(state, subFolder, lookup);
      if (subState === "some")
        return "some";
      if (!state)
        state = subState;
      if (subState !== state)
        return "some";
    }
    return state;
  }
  getFolderItemState(folder, lookup) {
    if (!folder.items.length)
      return void 0;
    let itemState;
    for (let item of folder.items) {
      if (itemState === void 0) {
        itemState = lookup.has(item.model.id) ? "all" : "none";
        continue;
      }
      if (lookup.has(item.model.id)) {
        if (itemState === "none")
          return "some";
      } else {
        if (itemState === "all")
          return "some";
      }
    }
    return itemState;
  }
  //</editor-fold>
  isActive$(item) {
    const id = isString(item) ? item : item.id;
    return this.itemIds$.pipe(
      map((lookup) => lookup.has(id))
    );
  }
  contains(item) {
    const id = isString(item) ? item : item.id;
    return this.itemIds.has(id);
  }
}

export { TreeRange, TreeSelection };
