var RenderDataTypes = /* @__PURE__ */ ((RenderDataTypes2) => {
  RenderDataTypes2["String"] = "String";
  RenderDataTypes2["Html"] = "HTML";
  RenderDataTypes2["Number"] = "Number";
  RenderDataTypes2["Decimal"] = "Decimal";
  RenderDataTypes2["Bool"] = "Bool";
  RenderDataTypes2["Date"] = "Date";
  RenderDataTypes2["DateTime"] = "DateTime";
  RenderDataTypes2["Time"] = "Time";
  RenderDataTypes2["Icon"] = "Icon";
  RenderDataTypes2["Image"] = "Image";
  RenderDataTypes2["Template"] = "Template";
  RenderDataTypes2["Void"] = "Void";
  return RenderDataTypes2;
})(RenderDataTypes || {});
var SortingTypes = /* @__PURE__ */ ((SortingTypes2) => {
  SortingTypes2["Alph"] = "Alphabetical";
  SortingTypes2["Num"] = "Numerical";
  SortingTypes2["Date"] = "Dates";
  SortingTypes2["Bool"] = "Boolean";
  return SortingTypes2;
})(SortingTypes || {});

export { RenderDataTypes, SortingTypes };
