import { Subscription, BehaviorSubject, debounceTime, startWith, asyncScheduler, EMPTY } from 'rxjs';
import { distinctUntilChanged, map, throttleTime, catchError, skip } from 'rxjs/operators';
import { deepCopy } from '@juulsgaard/ts-tools';
import { DataFilter, IndividualDataFilter } from './data-filter.js';
import { cache } from '@juulsgaard/rxjs-tools';

class FilterServiceState {
  constructor(filterState, filters) {
    this.filters = filters;
    this.filterState = deepCopy(filterState);
  }
  filter(list) {
    for (let f of this.filters) {
      list = f.filter(this.filterState, list);
    }
    return list;
  }
}
class FilterService {
  constructor(state, saveAdapter) {
    this.saveAdapter = saveAdapter;
    this._filters = [];
    this._serializerSub = new Subscription();
    this._resetState = deepCopy(state);
    this._state$ = new BehaviorSubject(state);
    this.state$ = this._state$.asObservable();
    this.activeFilters$ = this.state$.pipe(
      debounceTime(200),
      startWith(this.state),
      distinctUntilChanged(),
      map((state2) => this._filters.reduce((acc, x) => x.isActive(state2) ? acc + 1 : acc, 0)),
      cache()
    );
    this.filter$ = this.state$.pipe(
      debounceTime(500),
      startWith(this.state),
      distinctUntilChanged(),
      map((state2) => new FilterServiceState(state2, this._filters)),
      cache()
    );
  }
  get state() {
    return this._state$.value;
  }
  set state(state) {
    this._state$.next(state);
  }
  set delta(state) {
    this.state = { ...this.state, ...state };
  }
  addFullFilter(isActive, filter) {
    this._filters.push(new DataFilter(filter, isActive));
  }
  addFilter(isActive, filter) {
    this._filters.push(new IndividualDataFilter(filter, isActive));
  }
  update(change) {
    const result = change(this.state);
    if (this.state === result)
      return;
    this.delta = result;
  }
  clearFilter() {
    this.state = deepCopy(this._resetState);
  }
  dispose() {
    this.clearFilter();
    this._serializerSub?.unsubscribe();
  }
  withSerializer(serialize, deserialize, subscribe = false) {
    if (!this.saveAdapter)
      throw Error(`Can't use a filter serializer without an adapter`);
    this.deserialize(deserialize, subscribe).then(() => {
      this._serializerSub.add(this._state$.pipe(
        throttleTime(500, asyncScheduler, { leading: true, trailing: true }),
        map(serialize),
        catchError((err) => {
          console.log("Failed to serialize filter", this.constructor.name, err);
          return EMPTY;
        }),
        distinctUntilChanged()
      ).subscribe((state) => this.saveAdapter?.writeState(state)));
    });
  }
  async deserialize(deserialize, subscribe) {
    await this.saveAdapter.readState().then(deserialize).then(
      (state) => this.delta = state,
      (err) => console.log("Failed to deserialize filter", this.constructor.name, err)
    );
    if (subscribe) {
      this._serializerSub.add(
        this.saveAdapter.subscribe().pipe(
          skip(1),
          map(deserialize),
          catchError((err) => {
            console.log("Failed to deserialize filter", this.constructor.name, err);
            return EMPTY;
          })
        ).subscribe((state) => this.delta = state)
      );
    }
  }
}
class TreeFolderFilterService extends FilterService {
}
class TreeItemFilterService extends FilterService {
}

export { FilterService, FilterServiceState, TreeFolderFilterService, TreeItemFilterService };
