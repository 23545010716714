import { ListDataSourceConfig } from './list-source/list-source-config.js';
import { TreeDataOptionConfig } from './tree-source/tree-source-config.js';

var DataSource;
((DataSource2) => {
  function List() {
    return new ListDataSourceConfig();
  }
  DataSource2.List = List;
  ((Tree2) => {
    function WithItemParent(parentId) {
      return new TreeDataOptionConfig(parentId, void 0);
    }
    Tree2.WithItemParent = WithItemParent;
    function WithFolderChildren(folderChildren) {
      return new TreeDataOptionConfig(void 0, folderChildren);
    }
    Tree2.WithFolderChildren = WithFolderChildren;
  })(DataSource2.Tree || (DataSource2.Tree = {}));
})(DataSource || (DataSource = {}));
var Data;
((Data2) => {
  function FromList() {
    return new ListDataSourceConfig();
  }
  Data2.FromList = FromList;
  ((FromTree2) => {
    function WithItemParent(parentId) {
      return new TreeDataOptionConfig(parentId, void 0);
    }
    FromTree2.WithItemParent = WithItemParent;
    function WithFolderChildren(folderChildren) {
      return new TreeDataOptionConfig(void 0, folderChildren);
    }
    FromTree2.WithFolderChildren = WithFolderChildren;
  })(Data2.FromTree || (Data2.FromTree = {}));
})(Data || (Data = {}));

export { Data, DataSource };
