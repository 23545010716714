import { BehaviorSubject, merge, EMPTY, combineLatest, of, auditTime, delay, firstValueFrom } from 'rxjs';
import { distinctUntilChanged, map, switchMap, skip, filter } from 'rxjs/operators';
import { cache, latestValueFromOrDefault } from '@juulsgaard/rxjs-tools';

class TreeState {
  constructor(dataSource, folderId$, itemId$) {
    this.dataSource = dataSource;
    this._folderId$ = new BehaviorSubject(void 0);
    this._itemId$ = new BehaviorSubject(void 0);
    this.folderId$ = merge(this._folderId$, folderId$ ?? EMPTY).pipe(cache(), distinctUntilChanged());
    this.itemId$ = merge(this._itemId$, itemId$ ?? EMPTY).pipe(cache(), distinctUntilChanged());
    this.asideData$ = dataSource.getSidebarData(this.folderId$);
    this.baseFolder$ = combineLatest([dataSource.baseFolderLookup$, this.folderId$]).pipe(
      map(([lookup, id]) => id ? lookup.get(id) : void 0),
      cache()
    );
    this.folder$ = this.baseFolder$.pipe(map((x) => x?.model));
    this.metaFolder$ = this.folderId$.pipe(
      switchMap(
        (id) => !id ? of(void 0) : dataSource.metaFolderLookup$.pipe(map((lookup) => lookup.get(id)))
      ),
      cache()
    );
    const validatedFolderId$ = this.baseFolder$.pipe(map((x) => x?.model.id), distinctUntilChanged());
    const baseItem$ = combineLatest([dataSource.baseItemLookup$, this.itemId$]).pipe(
      map(([lookup, id]) => id ? lookup.get(id) : void 0),
      cache()
    );
    this.baseItem$ = combineLatest([baseItem$, validatedFolderId$]).pipe(
      auditTime(0),
      map(([item, folderId]) => !folderId || !item ? void 0 : item.folderId === folderId ? item : void 0),
      cache()
    );
    this.item$ = this.baseItem$.pipe(map((x) => x?.model));
    this.metaItem$ = this.baseItem$.pipe(
      map((x) => x?.model.id),
      distinctUntilChanged(),
      switchMap(
        (itemId) => !itemId ? of(void 0) : dataSource.metaItemLookup$.pipe(
          map((x) => x.get(itemId))
        )
      ),
      cache()
    );
    this.folderChanges$ = this.folder$.pipe(
      distinctUntilChanged((a, b) => a?.id === b?.id),
      skip(1),
      filter((x) => !!x)
    );
    this.itemChanges$ = this.item$.pipe(
      distinctUntilChanged((a, b) => a?.id === b?.id),
      skip(1),
      filter((x) => !!x)
    );
    this.navChanges$ = combineLatest([
      this.folder$.pipe(delay(0)),
      // Delay to bring to same tick as item$
      this.item$
    ]).pipe(
      auditTime(0),
      distinctUntilChanged(([a1, a2], [b1, b2]) => a1?.id === b1?.id && a2?.id === b2?.id),
      skip(1),
      map(
        ([a, b]) => a && b ? [a, b] : a ? [a] : []
      )
    );
  }
  subscribe(observer) {
    return this.item$.subscribe(observer);
  }
  //<editor-fold desc="Set Item">
  setItem(item) {
    if (!item) {
      this._itemId$.next(void 0);
      return true;
    }
    this._folderId$.next(item.folderId);
    this._itemId$.next(item.model.id);
    return true;
  }
  async setItemId(itemId) {
    if (!itemId) {
      this._itemId$.next(void 0);
      return true;
    }
    const item = await firstValueFrom(this.dataSource.baseItemLookup$.pipe(
      map((lookup) => lookup.get(itemId))
    ));
    if (!item)
      return false;
    return this.setItem(item);
  }
  //</editor-fold>
  //<editor-fold desc="Set Folder">
  setFolder(folder) {
    return this.setFolderId(folder?.model.id);
  }
  setFolderId(folderId) {
    if (!folderId) {
      this._folderId$.next(void 0);
      this._itemId$.next(void 0);
      return true;
    }
    if (this._folderId$.value === folderId)
      return false;
    this._folderId$.next(folderId);
    this._itemId$.next(void 0);
    return true;
  }
  //</editor-fold>
  folderActive$(folderId) {
    return this.baseFolder$.pipe(map((f) => !!f && f.model.id === folderId));
  }
  itemActive$(itemId) {
    return this.baseItem$.pipe(map((f) => !!f && f.model.id === itemId));
  }
  clearItem() {
    this.setItem(void 0);
  }
  clearFolder() {
    this.setFolder(void 0);
  }
}
class TreeItemState {
  constructor(dataSource, itemId$) {
    this.dataSource = dataSource;
    this._itemId$ = new BehaviorSubject(void 0);
    this._folderId$ = new BehaviorSubject(void 0);
    this.itemId$ = merge(this._itemId$, itemId$ ?? EMPTY).pipe(cache(), distinctUntilChanged());
    this.baseItem$ = combineLatest([dataSource.baseItemLookup$, this.itemId$]).pipe(
      map(([lookup, id]) => id ? lookup.get(id) : void 0),
      distinctUntilChanged(),
      cache()
    );
    this.item$ = this.baseItem$.pipe(map((x) => x?.model));
    this.metaItem$ = this.itemId$.pipe(
      switchMap(
        (id) => !id ? of(void 0) : dataSource.metaItemLookup$.pipe(map((lookup) => lookup.get(id)))
      ),
      distinctUntilChanged(),
      cache()
    );
    this.folderId$ = this.baseItem$.pipe(
      switchMap((x) => x ? of(x.folderId) : this._folderId$),
      distinctUntilChanged(),
      cache()
    );
    this.asideData$ = dataSource.getSidebarData(this.folderId$);
    this.baseFolder$ = combineLatest([dataSource.baseFolderLookup$, this.folderId$]).pipe(
      map(([lookup, id]) => id ? lookup.get(id) : void 0),
      distinctUntilChanged(),
      cache()
    );
    this.folder$ = this.baseFolder$.pipe(map((x) => x?.model));
    this.metaFolder$ = this.folderId$.pipe(
      switchMap(
        (id) => !id ? of(void 0) : dataSource.metaFolderLookup$.pipe(map((lookup) => lookup.get(id)))
      ),
      distinctUntilChanged(),
      cache()
    );
    this.folderChanges$ = this.folder$.pipe(
      distinctUntilChanged((a, b) => a?.id === b?.id),
      skip(1),
      filter((x) => !!x)
    );
    this.itemChanges$ = this.item$.pipe(
      distinctUntilChanged((a, b) => a?.id === b?.id),
      skip(1),
      filter((x) => !!x)
    );
  }
  subscribe(observer) {
    return this.item$.subscribe(observer);
  }
  //<editor-fold desc="Set Item">
  setItem(item) {
    return this.setItemId(item?.model.id);
  }
  setItemId(itemId) {
    if (itemId) {
      this._itemId$.next(itemId);
      this._folderId$.next(void 0);
      return true;
    }
    const currentFolderId = latestValueFromOrDefault(this.folderId$);
    this._itemId$.next(itemId);
    this._folderId$.next(currentFolderId);
    return true;
  }
  //</editor-fold>
  //<editor-fold desc="Set Folder">
  setFolder(folder) {
    return this.setFolderId(folder?.model.id);
  }
  setFolderId(folderId) {
    if (!folderId) {
      this._folderId$.next(void 0);
      this._itemId$.next(void 0);
      return true;
    }
    const currentFolderId = latestValueFromOrDefault(this.folderId$);
    if (folderId === currentFolderId)
      return false;
    this._folderId$.next(folderId);
    this._itemId$.next(void 0);
    return true;
  }
  setOnlyFolderId(folderId) {
    this._folderId$.next(folderId);
  }
  //</editor-fold>
  folderActive$(folderId) {
    return this.baseFolder$.pipe(map((f) => !!f && f.model.id === folderId));
  }
  itemActive$(itemId) {
    return this.baseItem$.pipe(map((f) => !!f && f.model.id === itemId));
  }
  clearItem() {
    this.setItem(void 0);
  }
}

export { TreeItemState, TreeState };
