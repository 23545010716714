import { RenderDataTypes } from '../models/render-types.js';
import { getSortingTypeSorting } from '../lib/sorting.js';
import { TreeDataSource } from './tree-data-source.js';
import { sortByIndexAsc } from '../lib/index-sort.js';
import { arrToObj, lowerFirst, getSelectorFn } from '@juulsgaard/ts-tools';

class TreeDataOptionConfig {
  constructor(itemParentId, folderChildren) {
    this.options = {
      itemParentId,
      folderChildren,
      folderActions: [],
      itemActions: [],
      folderFlags: [],
      itemFlags: [],
      moveActions: {}
    };
  }
  /**
   * Define a parent property on the folder type
   * This will allow for an infinitely nested tree structure
   * @param parentId - The parentId prop
   */
  withFolderParent(parentId) {
    this.options.folderParentId = parentId;
    return this;
  }
  /**
   * Indicate that the folders should be sorted using an index property
   */
  withSortedFolders() {
    this.options.folderSort = sortByIndexAsc;
    return this;
  }
  /**
   * Add sorting to the folders in tree view
   * @param sort
   */
  withFolderSort(sort) {
    this.options.folderSort = sort;
    return this;
  }
  /**
   * Indicate that the items should be sorted using an index property
   */
  withSortedItems() {
    this.options.itemSort = sortByIndexAsc;
    return this;
  }
  /**
   * Add sorting to the items in tree view
   * @param sort
   */
  withItemSort(sort) {
    this.options.itemSort = sort;
    return this;
  }
  /**
   * Add a filter service for the folders
   * @param service - The filter service
   */
  withFolderFilter(service) {
    this.options.folderFilterService = service;
    return this;
  }
  /**
   * Add a filter service for the items
   * @param service - The filter service
   */
  withItemFilter(service) {
    this.options.itemFilterService = service;
    return this;
  }
  /**
   * Add an action that can be performed on a folder
   * @param name - The display name of the Action
   * @param icon - The icon for the Action
   * @param action - The action to be performed
   * @param options - Options to configure the action
   */
  addFolderAction(name, icon, action, options) {
    this.options.folderActions.push({
      name,
      icon,
      action,
      ...options
    });
    return this;
  }
  /**
   * Add a navigation action that can be performed on a folder
   * @param name - The display name of the Action
   * @param icon - The icon for the Action
   * @param route - A generator for the route to use for navigation
   * @param options - Options to configure the action
   */
  addFolderNavigation(name, icon, route, options) {
    this.options.folderActions.push({
      name,
      icon,
      route,
      ...options
    });
    return this;
  }
  /**
   * Define a flag for folders
   * Flags are optional icons that can be shown next to folders
   * @param name - The name of the flag
   * @param icon - The icon used for rendering the flag
   * @param filter - A filter to determine if the flag should be shown
   * @param inactiveIcon - Define an icon for when the flag is not active
   */
  addFolderFlag(name, icon, filter, inactiveIcon) {
    this.options.folderFlags.push({ name, icon, filter, inactiveIcon });
    return this;
  }
  /**
   * Add an action that can be performed on an item
   * @param name - The display name of the Action
   * @param icon - The icon for the Action
   * @param action - The action to be performed
   * @param options - Options to configure the action
   */
  addItemAction(name, icon, action, options) {
    this.options.itemActions.push({
      name,
      icon,
      action,
      ...options
    });
    return this;
  }
  /**
   * Add a navigation action that can be performed on a folder
   * @param name - The display name of the Action
   * @param icon - The icon for the Action
   * @param route - A generator for the route to use for navigation
   * @param options - Options to configure the action
   */
  addItemNavigation(name, icon, route, options) {
    this.options.itemActions.push({
      name,
      icon,
      route,
      ...options
    });
    return this;
  }
  /**
   * Define a flag for items
   * Flags are optional icons that can be shown next to items
   * @param name - The name of the flag
   * @param icon - The icon used for rendering the flag
   * @param filter - A filter to determine if the flag should be shown
   * @param inactiveIcon - Define an icon for when the flag is not active
   */
  addItemFlag(name, icon, filter, inactiveIcon) {
    this.options.itemFlags.push({ name, icon, filter, inactiveIcon });
    return this;
  }
  /**
   * Define actions for moving and relocating items
   * @param actions
   */
  addMoveActions(actions) {
    this.options.moveActions = actions;
    return this;
  }
  /**
   * Add Tree Rendering to this config
   */
  asTree() {
    return {
      folderRow: (getName, getIcon, getBonus, getTooltip) => {
        const folderConfig = {
          folderName: getName,
          folderIcon: getIcon ?? void 0,
          folderBonus: getBonus ?? void 0,
          folderTooltip: getTooltip ?? void 0
        };
        return {
          itemRow: (getName2, getIcon2, getBonus2, getTooltip2) => new TreeDataSourceConfig(this.options, {
            ...folderConfig,
            itemName: getName2,
            itemIcon: getIcon2 ?? void 0,
            itemBonus: getBonus2 ?? void 0,
            itemTooltip: getTooltip2 ?? void 0
          })
        };
      }
    };
  }
  /**
   * Don't add Tree Rendering
   * This will make it so that this config can only be used for searching
   */
  asSearchOnly() {
    return new TreeDataSourceConfig(this.options);
  }
}
class TreeDataSourceConfig {
  constructor(options, treeConfig) {
    this.options = options;
    this.treeConfig = treeConfig;
    this.searchColumns = [];
    this.hiddenSearchColumns = [];
    // TODO: Add config builder for sort columns
    this.hiddenSortColumns = [];
    this.column = {
      folder: arrToObj(
        Object.entries(RenderDataTypes),
        ([key]) => lowerFirst(key),
        ([_, type]) => new SearchColumnConfig(type, this)
      ),
      noFolder: (id) => new SearchColumnMidConfig({
        id,
        folder: { mapData: () => void 0, dataType: RenderDataTypes.Void }
      }, this),
      path: (getName) => this.column.folder.string.add("path", "Path", (_, x) => x.path.map((x2) => getName(x2.model, x2)).join("/")).item.string.map((_, { folder }) => [
        ...folder.path.map((x) => getName(x.model, x)),
        getName(folder.model, folder)
      ].join("/")).done()
    };
    this.search = {
      folder: (id, map, weight) => {
        this.hiddenSearchColumns.push({ mapFolder: map, id, weight });
        return this;
      },
      item: (id, map, weight) => {
        this.hiddenSearchColumns.push({ mapItem: map, id, weight });
        return this;
      }
    };
  }
  finish() {
    return new TreeDataSource(this.options, this.searchColumns, this.hiddenSearchColumns, this.hiddenSortColumns, this.treeConfig);
  }
}
class SearchColumnConfig {
  constructor(type, rootConfig) {
    this.type = type;
    this.rootConfig = rootConfig;
  }
  /**
   * Use an existing property as a column
   * @param key - The property to use
   * @param title - The name of the column
   */
  prop(key, title) {
    return this.continue({
      id: key,
      title,
      folder: {
        mapData: getSelectorFn(key),
        dataType: this.type
      }
    });
  }
  /**
   * Create a new column using a custom mapping
   * @param id - The ID of the column
   * @param title - The name of the column
   * @param map - The data mapping for the column
   */
  add(id, title, map) {
    return this.continue({
      id,
      title,
      folder: {
        mapData: map,
        dataType: this.type
      }
    });
  }
  continue(config) {
    return new SearchColumnMidConfig(config, this.rootConfig);
  }
}
class SearchColumnMidConfig {
  constructor(partialConfig, rootConfig) {
    this.partialConfig = partialConfig;
    this.rootConfig = rootConfig;
    this.item = arrToObj(
      Object.entries(RenderDataTypes),
      ([key]) => lowerFirst(key),
      ([_, type]) => new SearchColumnItemConfig(partialConfig, type, rootConfig)
    );
  }
  /** Don't include items in the column */
  noItem() {
    return new SearchColumnFinalConfig(
      {
        ...this.partialConfig,
        item: { mapData: () => void 0, dataType: RenderDataTypes.Void }
      },
      this.rootConfig
    );
  }
}
class SearchColumnItemConfig {
  constructor(partialConfig, type, rootConfig) {
    this.partialConfig = partialConfig;
    this.type = type;
    this.rootConfig = rootConfig;
  }
  /**
   * Use an existing property for the item data
   * @param key - The property to use
   */
  prop(key) {
    return new SearchColumnFinalConfig(
      {
        ...this.partialConfig,
        item: { mapData: getSelectorFn(key), dataType: this.type }
      },
      this.rootConfig
    );
  }
  /**
   * Generate item data for the columns using a custom mapping
   * @param map - The data mapping for the column
   */
  map(map) {
    return new SearchColumnFinalConfig(
      {
        ...this.partialConfig,
        item: {
          mapData: map,
          dataType: this.type
        }
      },
      this.rootConfig
    );
  }
}
class SearchColumnFinalConfig {
  constructor(config, rootConfig) {
    this.config = config;
    this.rootConfig = rootConfig;
  }
  /**
   * Add sorting capabilities to the column
   * @param mapFolder - Map folder data for the sorting
   * @param mapItem - Map the item data for the sorting
   * @param type - The data type for the sorting
   */
  withSorting(type, mapFolder, mapItem) {
    this.config.sorting = {
      folderSortData: mapFolder,
      itemSortData: mapItem,
      sortFn: getSortingTypeSorting(type)
    };
    return this;
  }
  /**
   * Use this column for searching
   * @param weight - Set a custom weight for the search
   */
  includeInSearch(weight) {
    this.config.searching = {
      mapFolder: (folder, meta) => this.config.folder.mapData(folder, meta)?.toString(),
      mapItem: (item, meta) => this.config.item.mapData(item, meta)?.toString(),
      weight
    };
    return this;
  }
  /**
   * Finish defining the column
   */
  done() {
    this.rootConfig.searchColumns.push(this.config);
    return this.rootConfig;
  }
}

export { TreeDataOptionConfig };
