import { isString } from '@juulsgaard/ts-tools';
import { combineLatest, combineLatestWith, asyncScheduler } from 'rxjs';
import { map, distinctUntilChanged, throttleTime } from 'rxjs/operators';
import { ObservableSet, cache } from '@juulsgaard/rxjs-tools';
import { ListState } from './list-state.js';

class ListSelection extends ListState {
  constructor(dataSource) {
    super(dataSource);
    this.empty$ = this.item$.pipe(map((x) => !x));
  }
  clear() {
    this.setItem(void 0);
  }
  /**
   * Toggle the item in the selection
   * @param item - The item to toggle
   * @param state - A forced state (`true` = always add, `false` = always delete)
   * @returns The applied change (`true` = item added, `false` = item removed, `undefined` = nothing changed)
   */
  toggleItem(item, state) {
    const id = isString(item) ? item : item.id;
    if (this._itemId$.value === item) {
      if (state === true)
        return void 0;
      this.setItem(void 0);
      return false;
    }
    if (state === false)
      return void 0;
    this.setItem(id);
    return true;
  }
  isActive$(folder) {
    const id = isString(folder) ? folder : folder.id;
    return this.itemId$.pipe(
      map((itemId) => itemId === id)
    );
  }
}
class ListRange {
  constructor(dataSource) {
    this._itemIds$ = new ObservableSet();
    this.itemIds$ = this._itemIds$.value$;
    this.itemIdArray$ = this._itemIds$.array$;
    this.items$ = combineLatest([dataSource.itemLookup$, this.itemIdArray$.pipe(distinctUntilChanged())]).pipe(
      map(([lookup, ids]) => ids.map((id) => lookup.get(id)).filter((x) => !!x)),
      cache()
    );
    this.empty$ = this.items$.pipe(map((x) => !x.length));
    this.selectAllState$ = dataSource.items$.pipe(
      combineLatestWith(this.itemIds$),
      throttleTime(500, asyncScheduler, { leading: true, trailing: true }),
      map(([items, selected]) => {
        if (selected.size < 1)
          return "none";
        if (selected.size < items.length)
          return items.some((x) => selected.has(x.id)) ? "some" : "none";
        if (items.every((x) => selected.has(x.id)))
          return "all";
        return "some";
      }),
      distinctUntilChanged(),
      cache()
    );
    this.allSelected$ = this.selectAllState$.pipe(map((x) => x === "all"));
    this.someSelected$ = this.selectAllState$.pipe(map((x) => x === "some"));
  }
  get itemIds() {
    return this._itemIds$.value;
  }
  get itemIdArray() {
    return this._itemIds$.array;
  }
  subscribe(observer) {
    return this.items$.subscribe(observer);
  }
  setRange(list) {
    this._itemIds$.set(list.map((x) => isString(x) ? x : x.id));
  }
  clear() {
    this._itemIds$.clear();
  }
  /**
   * Toggle an item in the selection
   * @param item - The item to toggle
   * @param state - A forced state (`true` = always add, `false` = always delete)
   * @returns The applied change (`true` = item added, `false` = item removed, `undefined` = nothing changed)
   */
  toggleItem(item, state) {
    const id = isString(item) ? item : item.id;
    return this._itemIds$.toggle(id, state);
  }
  isActive$(folder) {
    const id = isString(folder) ? folder : folder.id;
    return this.itemIds$.pipe(
      map((lookup) => lookup.has(id))
    );
  }
  contains(item) {
    const id = isString(item) ? item : item.id;
    return this.itemIds.has(id);
  }
}

export { ListRange, ListSelection };
