import { RenderDataTypes, SortingTypes } from '../models/render-types.js';
import { ListDataSource } from './list-data-source.js';
import { getRenderDataTypeSorting, getSortingTypeSorting } from '../lib/sorting.js';
import { arrToObj, lowerFirst, getSelectorFn, isString } from '@juulsgaard/ts-tools';

class ListDataSourceConfig {
  constructor() {
    this.tableColumns = /* @__PURE__ */ new Map();
    this.searchColumns = /* @__PURE__ */ new Map();
    this.sortColumns = /* @__PURE__ */ new Map();
    this.options = {
      pureMapping: false,
      paginated: false,
      pageSize: 40,
      actions: [],
      indexSorted: false,
      flags: [],
      cssClasses: [],
      defaultSortOrder: "asc"
    };
    this.table = arrToObj(
      Object.entries(RenderDataTypes),
      ([key, _]) => lowerFirst(key),
      ([_, type]) => new TableColumnConfig(type, this)
    );
    const sort = arrToObj(
      Object.entries(SortingTypes),
      ([key, _]) => lowerFirst(key),
      ([_, type]) => new SortColumnConfig(type, this)
    );
    this.sort = { ...sort, model: new SortModelConfig(this) };
    this.search = new SearchColumnConfig(this);
  }
  addList(firstLine) {
    this.listConfig = { firstLine, avatarPlaceholder: "assets/placeholders/image.webp" };
    return new ListConfig(this);
  }
  addGrid(title) {
    this.gridConfig = { title, imagePlaceholder: "assets/placeholders/image.webp" };
    return new GridConfig(this);
  }
  addFlag(name, icon, filter, inactiveIcon) {
    this.options.flags.push({ name, icon, filter, inactiveIcon });
    return this;
  }
  addClass(cssClass, condition) {
    this.options.cssClasses.push({ cssClass, condition });
    return this;
  }
  addAction(name, icon, action, options) {
    this.options.actions.push({
      name,
      icon,
      action,
      ...options
    });
    return this;
  }
  addNavigation(name, icon, route, options) {
    this.options.actions.push({
      name,
      icon,
      route,
      ...options
    });
    return this;
  }
  hasIndexSorting() {
    this.options.indexSorted = true;
    return this;
  }
  isPure() {
    this.options.pureMapping = true;
    return this;
  }
  finish() {
    return new ListDataSource(this.options, this.tableColumns, this.searchColumns, this.sortColumns, this.listConfig, this.gridConfig);
  }
  withPagination(pageSize) {
    this.options.paginated = true;
    this.options.pageSize = pageSize ?? this.options.pageSize;
    return this;
  }
  withFilterService(service) {
    this.options.filterService = service;
    return this;
  }
  defaultSortDesc() {
    this.options.defaultSortOrder = "desc";
    return this;
  }
}
class TableColumnConfig {
  constructor(type, config) {
    this.type = type;
    this.config = config;
  }
  /**
   * Define a column based on a property
   * @param key - The property to use
   * @param title - The column name
   * @param options - Column options
   */
  prop(key, title, options) {
    const map = getSelectorFn(key);
    this.config.tableColumns.set(key.toString(), {
      id: key.toString(),
      title,
      mapData: map,
      dataType: this.type,
      sortFn: this.getSort(map, options),
      defaultSort: !!options?.defaultSort,
      searchable: !!options?.searchable,
      searchWeight: options?.searchWeight
    });
    return this.config;
  }
  /**
   * Define a custom column based on a custom mopping
   * @param id - ID of the new column
   * @param title - The name of the column
   * @param map - Data mapping for the column
   * @param options - Column options
   */
  add(id, title, map, options) {
    this.config.tableColumns.set(id, {
      id,
      title,
      mapData: map,
      dataType: this.type,
      sortFn: this.getSort(map, options),
      defaultSort: !!options?.defaultSort,
      searchable: !!options?.searchable,
      searchWeight: options?.searchWeight
    });
    return this.config;
  }
  getSort(map, options) {
    if (!options)
      return void 0;
    if (options.customSort)
      return options.customSort;
    if (!options.typeSort)
      return void 0;
    const sortFn = getRenderDataTypeSorting(this.type);
    if (!sortFn)
      return void 0;
    return (a, b) => sortFn(map(a), map(b));
  }
}
class SearchColumnConfig {
  constructor(config) {
    this.config = config;
  }
  /**
   * Search based on an existing property
   * @param key - The property to use
   * @param weight - Optional search weighting
   */
  prop(key, weight) {
    const map = getSelectorFn(key);
    this.config.searchColumns.set(key.toString(), {
      id: key.toString(),
      mapData: map,
      weight
    });
    return this.config;
  }
  /**
   * Define custom search data
   * @param id - ID of the data
   * @param map - Define the data mapping
   * @param weight - Optional search weighting
   */
  add(id, map, weight) {
    this.config.searchColumns.set(id, {
      id,
      mapData: map,
      weight
    });
    return this.config;
  }
}
class SortColumnConfig {
  constructor(type, config) {
    this.config = config;
    this.baseSort = getSortingTypeSorting(type);
  }
  /**
   * Add sorting based on an existing property
   * @param key - The property
   * @param title - The sorting name
   * @param defaultSort - Define if this should be the default sort
   */
  prop(key, title, defaultSort) {
    const map = getSelectorFn(key);
    this.config.sortColumns.set(key.toString(), {
      id: key.toString(),
      title,
      sortFn: (a, b) => this.baseSort(map(a), map(b)),
      defaultSort: !!defaultSort
    });
    return this.config;
  }
  /**
   * Define a custom sorting based on data mapping
   * @param id - The ID of the sort
   * @param map - The data used for the sorting
   * @param title - The name of the sort
   * @param defaultSort - Define if this should be the default sort
   */
  add(id, map, title, defaultSort) {
    this.config.sortColumns.set(id, {
      id,
      title,
      sortFn: (a, b) => this.baseSort(map(a), map(b)),
      defaultSort: !!defaultSort
    });
    return this.config;
  }
}
class SortModelConfig {
  constructor(config) {
    this.config = config;
  }
  /**
   * Define the model sort
   * @param id - ID of the sort
   * @param title - The name of the sort
   * @param sort - The sorting method
   * @param defaultSort - Define if this should be the default sort
   */
  add(id, title, sort, defaultSort) {
    this.config.sortColumns.set(id, {
      id,
      title,
      sortFn: sort,
      defaultSort: !!defaultSort
    });
    return this.config;
  }
}
class ListConfig {
  constructor(config) {
    this.config = config;
    this.listConfig = config.listConfig;
  }
  /**
   * Add a second line to the List Rendering
   * @param secondLine
   */
  secondLine(secondLine) {
    this.listConfig.secondLine = secondLine;
    return this;
  }
  /**
   * Add an image to the List Rendering
   * @param avatar - The url mapping
   * @param cacheBuster - An optional cache buster for the image
   */
  avatar(avatar, cacheBuster) {
    this.listConfig.avatar = avatar;
    this.listConfig.avatarCacheBuster = cacheBuster;
    return this;
  }
  /**
   * Define a placeholder for the list image if it can't be found
   * @param avatarPlaceholder - Fallback URL
   */
  avatarPlaceholder(avatarPlaceholder) {
    this.listConfig.avatarPlaceholder = avatarPlaceholder;
    return this;
  }
  /**
   * Add an icon to the List Rendering
   * @param icon
   */
  icon(icon) {
    this.listConfig.icon = isString(icon) ? () => icon : icon;
    return this;
  }
  /**
   * Finish setting up List Rendering
   */
  finishList() {
    return this.config;
  }
  /**
   * Finish setting up the data source
   */
  finish() {
    return this.config.finish();
  }
}
class GridConfig {
  constructor(config) {
    this.config = config;
  }
  /**
   * Add a sub-title to the Grid Rendering
   * @param subTitle
   */
  subTitle(subTitle) {
    this.config.gridConfig.subTitle = subTitle;
    return this;
  }
  /**
   * Add an image to the Grid Rendering
   * @param image - The url mapping
   * @param cacheBuster - An optional cache buster for the image
   */
  image(image, cacheBuster) {
    this.config.gridConfig.image = image;
    this.config.gridConfig.imageCacheBuster = cacheBuster;
    return this;
  }
  /**
   * Define a placeholder for the grid image if it can't be found
   * @param placeholder - Fallback URL
   */
  imagePlaceholder(placeholder) {
    this.config.gridConfig.imagePlaceholder = placeholder;
    return this;
  }
  /**
   * Add an icon to the Grid Rendering
   * @param icon
   */
  icon(icon) {
    this.config.gridConfig.icon = isString(icon) ? () => icon : icon;
    return this;
  }
  /**
   * Finish setting up Grid Rendering
   */
  finishGrid() {
    return this.config;
  }
  /**
   * Finish setting up the data source
   */
  finish() {
    return this.config.finish();
  }
}

export { ListDataSourceConfig };
